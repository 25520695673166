import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Max And Min Using Divide And Conquer | A Helpful Line-by-Line Code Tutorial"
				}
				subHeader={""}
				description={
					"In computer science, divide and conquer is an algorithm design paradigm based on multi-branched recursion. A divide-and-conquer algorithm works by recursively breaking down a problem into two or more sub-problems of the same or related type, until these become simple enough to be solved directly. The solutions to the sub-problems are then combined to give a solution to the original problem."
				}
				keywords={[]}
				embeddedVideo={"EHRL2LbS5LU"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/bfbbac0239bc9d8cef26a29fa7e27790/raw/5dee0d591062b9107ff0a828a11827bf85484ddb/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
